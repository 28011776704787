<template>
  <div>
    {{auth}}
    {{testName}}
    {{serviceLang}}
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'

export default ({
  name: 'SampleLogin',

  data () {
    return {
    }
  },

  computed: {
    ...rootComputed,
    testName () {
      return this.auth.userEname
    }
  }
})
</script>
